import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { OnboardType } from 'enums/crm/crm';
import { TWO_COL_GRID_SIZES } from 'constants/grids';
import { FormValuesModel as ReviewDocumentFormValuesModel } from 'components/Forms/TemplateForms/CRM/ReviewDocumentForm';

// components
import FormField from '@core_components/FormField';
import SectionIntro from '@common_components/Texts/SectionIntro';
import IsPEPTooltip from 'components/Tooltips/TemplateTooltips/IsPEPTooltip';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import CountryFormSelect from 'components/Forms/FormComponents/SelectInputs/CountryFormSelect';
import ReviewDocumentForm from '../../ReviewDocumentForm';
import TrueFalseFormSelect from 'components/Forms/FormComponents/SelectInputs/TrueFalseFormSelect';
import NationalityFormSelect from 'components/Forms/FormComponents/SelectInputs/NationalityFormSelect';
import AddressForm, { AddressItemFormValue } from '../../AddressForm';
import PassportForm, {
  FormValuesModel as PassportFormItemModel,
} from '../../PassportForm';
import { Col, Row } from 'antd';
import { FieldArray, useFormikContext } from 'formik';

export interface PassportWithReviewFieldsModel
  extends PassportFormItemModel,
    ReviewDocumentFormValuesModel {
  canReview: boolean;
}

export interface FormValuesModel {
  isPEP: boolean | null;
  pepInformation: string;
  disabledPep?: boolean;
  isRegulated: boolean | null;
  regulationCountry: string[];
  addresses: AddressItemFormValue[];
  passports: PassportWithReviewFieldsModel[];
  nationality: string[];
  primaryAddressId: string | null;
}

const IdentificationForm = () => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <SectionIntro
        title={t('contacts.kyc_form.identification.title')}
        description={t('contacts.kyc_form.identification.description')}
      />
      <Row gutter={[16, 16]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={
              <DivAlignCenter>
                {t('contacts.kyc_form.identification.is_pep')} <IsPEPTooltip />
              </DivAlignCenter>
            }
            name="isPEP"
            disabled
            // disabled={values.disabledPep}
            component={TrueFalseFormSelect}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          {values.isPEP && (
            <FormField
              label={t('contacts.kyc_form.identification.pep_information')}
              name="pepInformation"
              component={FormInputTextArea}
              disabled
              // disabled={values.disabledPep}
            />
          )}
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.kyc_form.identification.is_regulated')}
            name="isRegulated"
            component={TrueFalseFormSelect}
            disabled
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          {values.isRegulated && (
            <FormField
              disabled
              label={t(
                'contacts.kyc_form.identification.regulated_in_countries',
              )}
              name="regulationCountry"
              component={CountryFormSelect}
              additionalProps={{ mode: 'multiple' }}
            />
          )}
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            disabled
            label={t('contacts.kyc_form.identification.nationality')}
            name="nationality"
            component={NationalityFormSelect}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
      </Row>

      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('contacts.kyc_form.identification.passport_section_title')}
      />
      <FieldArray
        name="passports"
        render={() =>
          values.passports.map((p, i) => (
            <React.Fragment key={p._id || i}>
              <PassportForm
                fieldName={`passports.${i}`}
                disabled
                index={i + 1}
              />
              {p.canReview ? (
                <ReviewDocumentForm namePrefix={`passports.${i}`} />
              ) : null}
            </React.Fragment>
          ))
        }
      />

      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('contacts.kyc_form.identification.address_section_title')}
        description={t(
          'contacts.kyc_form.identification.address_section_description',
        )}
      />
      <FieldArray
        name="addresses"
        render={() =>
          values.addresses.map((e, i) => (
            <AddressForm
              disabled
              key={e.id || i}
              index={i}
              entryType={OnboardType.Contact}
              baseName={`addresses.${i}`}
              isPrimary={e.id == values.primaryAddressId}
            />
          ))
        }
      />
    </>
  );
};

export default IdentificationForm;
