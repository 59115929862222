import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import ErrorHandlerService from '@services/error-handler/service';
import { message } from 'antd';
import { GraphNode } from 'components/Charts/GraphChart';
import { DateHelpers } from '@helpers/date';
import { IClientGroup } from 'typings/crm/client-group';
import { onboardingAnswerAPI } from 'api/crm/onboarding/onboardingAnswerAPI';
import {
  ReviewDetailsModel,
  clientGroupsAPI,
} from 'api/crm/clientGroup/clientGroupsAPI';
import { ReviewIdentificationDocumentsValidationSchema } from 'validations/crm/clientGroups';
import {
  ReviewOfExpiringDocument,
  expiringDocumentsAPI,
} from 'api/crm/expiringDocuments/expiringDocumentsAPI';

// components
import Form from '@core_components/Form';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import RequireAdditionalInformationModalDialog from 'components/Modals/TemplateModalDialogs/CRM/RequireAdditionalInformationModalDialog';
import IdentificationReviewForm, {
  FormValuesModel as IdentificationFormValuesModel,
} from 'components/Forms/TemplateForms/CRM/IdentificationReviewForm';

interface IProps {
  node: GraphNode | null;
  isViewOnly: boolean;
  clientGroup: IClientGroup;
  updateNodeCallback: () => void;
  updateClientGroupCallback: () => void;
}

const IdentificationDocuments = ({
  node,
  clientGroup,
  isViewOnly,
  updateClientGroupCallback,
  updateNodeCallback,
}: IProps) => {
  const { t } = useTranslation(['crm', 'common']);
  const [requireAdditionalInfoModalData, setRequireAdditionalInfoModalData] =
    useState<ReviewDetailsModel[] | null>(null);

  const { response: contactDocuments, loading: contactDocumentsLoader } =
    useFetch(
      () =>
        expiringDocumentsAPI.fetchExpiringDocuments({
          entityIds: node?.id,
          page: 1,
          limit: 100,
        }),
      [node],
    );

  const initialValues = useMemo<IdentificationFormValuesModel | null>(() => {
    if (!contactDocuments) {
      return null;
    }

    return {
      submitActionType: null,
      reviewProcess: {
        type: 'client-group-review',
        entryId: clientGroup._id,
      },
      passports: contactDocuments.data.length
        ? contactDocuments.data.map((e) => ({
            _id: e._id,
            canReview: e.status !== 'finalized',
            number: e.details.passportDetails.number || '',
            country: e.details.passportDetails.issuingCountry || '',
            issuedAt: e.details.passportDetails.issuedAt
              ? DateHelpers.formatDateToUTC(e.details.passportDetails.issuedAt)
              : null,
            expirationDate: e.expirationDate
              ? DateHelpers.formatDateToUTC(e.expirationDate)
              : null,
            document: e.details.passportDetails.documents.map((doc) => ({
              id: doc.id,
              name: doc.files[0].name,
              fileId: doc.files[0].id,
              file: null,
            })),

            notReviewed: !e.review,
            isApproved: e.review ? e.review.isValid : null,
            isRejected: e.review ? !e.review.isValid : null,
            reviewComment: e.review?.comment || '',
          }))
        : [],
    };
  }, [contactDocuments, clientGroup]);

  const handleSubmit = async (values: IdentificationFormValuesModel) => {
    if (!node) {
      return;
    }

    try {
      const formattedReviews = values.passports.reduce<
        ReviewOfExpiringDocument[]
      >((acc, e) => {
        if (e.canReview) {
          acc.push({
            expiringDocumentId: e._id as string,
            review: !e.notReviewed
              ? {
                  isValid: !!e.isApproved,
                  comment: e.isApproved ? undefined : e.reviewComment,
                }
              : null,
          });
        }

        return acc;
      }, []);

      await expiringDocumentsAPI.reviewExpiringDocuments({
        entityId: node.id,
        reviews: formattedReviews,
      });

      message.success(t('success', { ns: 'common' }));
      updateNodeCallback();

      if (values.submitActionType === 'start-approval-workflow') {
        await clientGroupsAPI.startApprovalWorkflow(clientGroup._id);
        updateClientGroupCallback();
      } else if (values.submitActionType === 'require-additional-info') {
        const response = await clientGroupsAPI.fetchReviewDetails(
          values.reviewProcess.entryId,
        );
        if (response.length) {
          setRequireAdditionalInfoModalData(response);
        } else {
          message.error(t('client_group.approval.review_details_error'));
        }
      }
    } catch (e) {
      ErrorHandlerService.handleError(e);
    }
  };

  const handleRequireAdditionalInfoModalSubmit = async () => {
    await onboardingAnswerAPI.requireAdditionalInfoForClientGroup(
      clientGroup._id,
    );
    updateClientGroupCallback();
  };

  return (
    <>
      <LoadingWrapper loading={contactDocumentsLoader}>
        <Form
          disabled={isViewOnly}
          onSubmit={handleSubmit}
          renderForm={IdentificationReviewForm}
          initialValues={initialValues}
          validationSchema={ReviewIdentificationDocumentsValidationSchema}
        />
      </LoadingWrapper>
      <RequireAdditionalInformationModalDialog
        title={t('client_group.approval.review_details_modal.title')}
        data={requireAdditionalInfoModalData}
        isVisible={!!requireAdditionalInfoModalData}
        onSubmit={handleRequireAdditionalInfoModalSubmit}
        closeCallback={() => setRequireAdditionalInfoModalData(null)}
      />
    </>
  );
};

export default IdentificationDocuments;
