import React, { memo, useCallback } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { generateUniqId } from '@helpers/utils';
import { FormValuesModel } from '../../ContactDetailsWithIdentificationForm';
import { ContactAddressType, OnboardType } from 'enums/crm/crm';

// components
import Text from '@core_components/Text';
import AddButton from '@common_components/Buttons/AddButton';
import AddressForm from '../../AddressForm';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

const MAX_ADDRESSES_CAN_BE_ADDED = 5;

const AddressesSection = memo(() => {
  const { t } = useTranslation('crm');
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const renderItems = useCallback(
    (arrayHelpers: FieldArrayRenderProps) => {
      return (
        <>
          {values.addresses.map((e, i) => (
            <AddressForm
              key={e.id}
              index={i}
              entryType={OnboardType.Contact}
              baseName={`addresses.${i}`}
              onRemove={() => arrayHelpers.remove(i)}
              isPrimary={values.primaryAddressId === e.id}
              onPrimaryChange={
                values.canUpdatePrimaryStatusForAddresses
                  ? (newValue) => setFieldValue('primaryAddressId', newValue)
                  : undefined
              }
            />
          ))}

          {values.addresses.length < MAX_ADDRESSES_CAN_BE_ADDED && (
            <HideIfDisabledForm>
              <AddButton
                onClick={() =>
                  arrayHelpers.push({
                    id: generateUniqId(),
                    type: [ContactAddressType.ResidentialAddress],
                    country: '',
                    city: '',
                    street: '',
                    state: '',
                    postal: '',
                  })
                }
              >
                {t('contacts.new.add_address')}
              </AddButton>
            </HideIfDisabledForm>
          )}
        </>
      );
    },
    [values, setFieldValue],
  );

  return (
    <>
      {!values.addresses.length ? (
        <Text>{t('contacts.new.addresses_are_not_provided')}</Text>
      ) : null}
      <FieldArray name="addresses" render={renderItems} />
    </>
  );
});

export default AddressesSection;
