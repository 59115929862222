import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import ReviewDocumentForm, {
  FormValuesModel as ReviewDocumentFormValuesModel,
} from 'components/Forms/TemplateForms/CRM/ReviewDocumentForm';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import ActionButtons, {
  FormValuesModel as ActionButtonsFormValuesModel,
} from '../ApplicationDocumentationForm/ActionButtons';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import PassportForm, {
  FormValuesModel as PassportFormItemModel,
} from 'components/Forms/TemplateForms/CRM/PassportForm';
import { FieldArray, useFormikContext } from 'formik';

export interface ReviewPassportFormItem
  extends PassportFormItemModel,
    ReviewDocumentFormValuesModel {
  canReview: boolean;
}

export interface FormValuesModel extends ActionButtonsFormValuesModel {
  passports: ReviewPassportFormItem[];
  reviewProcess: {
    type: 'contact-review' | 'client-group-review';
    entryId: string;
  };
}

const IdentificationReviewForm = () => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('contacts.kyc_form.identification.passport_section_title')}
      />
      <FieldArray
        name="passports"
        render={() =>
          values.passports.map((p, i) => (
            <React.Fragment key={p._id || i}>
              <PassportForm
                disabled
                fieldName={`passports.${i}`}
                index={i + 1}
              />
              {p.canReview ? (
                <ReviewDocumentForm namePrefix={`passports.${i}`} />
              ) : null}
            </React.Fragment>
          ))
        }
      />
      <HideIfDisabledForm>
        <ActionButtons />
      </HideIfDisabledForm>
    </>
  );
};

export default IdentificationReviewForm;
