import React, { useEffect, useRef, useState } from 'react';

//helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { Moment } from 'moment';
import { journalsAPI } from '../../../../../api/finance/journal/journalsAPI';
import { JournalModel } from './InnerForm/JournalForm';
import { FormikHelpers } from 'formik';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { NewJournalValidationSchema } from '../../../../../validations/accounting/journals';

//components
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import InnerForm from './InnerForm';
import LoadingWrapper from '../../../../WrapperComponents/LoadingWrapper';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Button, Typography } from 'antd';

export interface JournalFormValues {
  isReversalFrom?: number;
  journalId?: number;
  journalValueDate: Moment;
  journal: JournalModel;
  isReversalJournal: boolean;
  reversalJournal?: JournalModel;
  isEditMode: boolean;
}

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<JournalFormValues> {
  title: string;
  canEdit?: boolean;
  canReverse?: boolean;
}

const AddViewJournalModal = ({
  title,
  isVisible,
  initialValues,
  closeCallback,
  onSubmit,
  canEdit,
  canReverse,
}: IProps) => {
  const { t } = useTranslation(['finance', 'common']);
  const formRef = useRef<any>();

  const [isSubmitBtnsHidden, setIsSubmitBtnsHidden] = useState(true);

  useEffect(() => {
    if (!initialValues?.journalId && !isSubmitBtnsHidden) {
      setIsSubmitBtnsHidden(true);
      formRef.current.setFieldValue('isEditMode', false);
      formRef.current.setFieldValue('isReversalFrom', undefined);
    }
  }, [initialValues]);

  const handleEditClick = () => {
    setIsSubmitBtnsHidden(false);
    formRef.current.setFieldValue('isEditMode', true);
  };

  const handleReversalClick = async () => {
    if (initialValues?.journalId) {
      formRef.current.setFieldValue('isReversalJournal', true);
      const data = await journalsAPI.fetchReverseJournal(
        initialValues?.journalId,
      );

      const reversalJournal = {
        entries: AccountsHelpers.formatJournalEntryToFormJournalEntry(
          data.journalEntries,
        ),
      };

      formRef.current.setFieldValue('reversalJournal', reversalJournal);
    }

    setIsSubmitBtnsHidden(false);
  };

  const handleSubmit = async (
    values: JournalFormValues,
    helpers: FormikHelpers<JournalFormValues>,
  ) => {
    await onSubmit(values, helpers);
  };

  return (
    <Form<JournalFormValues>
      innerRef={formRef}
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={NewJournalValidationSchema}
      confirmExitWithoutSaving
      renderForm={({ values }) => (
        <ModalDialog
          width="90%"
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          hideFooterButtons={!initialValues}
          submitButtonProps={{
            hidden: !!initialValues?.journalId && isSubmitBtnsHidden,
          }}
          extendedFooterButtons={
            <>
              {isSubmitBtnsHidden && !!initialValues?.journalId && (
                <>
                  {canEdit && (
                    <Button size="large" onClick={handleEditClick}>
                      {t('modal.edit', { ns: 'common' })}
                    </Button>
                  )}

                  {canReverse && (
                    <StyledReverseButton
                      size="large"
                      onClick={handleReversalClick}
                    >
                      {t('journals.update.reverse')}
                    </StyledReverseButton>
                  )}
                </>
              )}
            </>
          }
        >
          <LoadingWrapper loading={!initialValues}>
            {!!initialValues && (
              <>
                {!!values.isReversalFrom && (
                  <Typography.Paragraph>{`${t('journals.update.reversal_journal_of')}: ${values.isReversalFrom}`}</Typography.Paragraph>
                )}
                <InnerForm isEdit={!!initialValues.journalId} />
              </>
            )}
          </LoadingWrapper>
        </ModalDialog>
      )}
    />
  );
};

const StyledReverseButton = styled(Button)`
  margin-left: ${({ theme }) => theme.marginSm};
`;

export default AddViewJournalModal;
