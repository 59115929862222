import APIConfig from '../../../config/api';

import { IEntity } from '../../../typings/crm/entity';
import { APIService } from '../../axiosInstance';
import { AnswerModel } from '../onboarding/onboardingAnswerAPI';
import { IContactModel } from '../../../typings/crm/contact';
import { AssociateTypes } from '../../../enums/crm/crm';
import { ExpiringDocument } from '../expiringDocuments/expiringDocumentsAPI';
import { clientGroupsAdapter } from 'apiAdapters/crm/clientGroups/clientGroupsAdapter';
import { IRelationshipTemplateModel } from '../../../typings/crm/relationship-template';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';
import {
  IOnboardingApproval,
  IOnboardingStatus,
} from '../../../typings/crm/onboarding';
import {
  IClientGroup,
  IClientGroupRelationshipScope,
  IClientGroupShortResponse,
  IClientGroupUser,
  INewComplianceBody,
  MemberModel,
} from '../../../typings/crm/client-group';

export interface IClientGroupResponse {
  applicationId: string;
  clientGroup: IClientGroup;
  activeRelationshipScope?: IClientGroupRelationshipScope;
  approvedRelationshipScope?: IClientGroupRelationshipScope;
  relationshipTemplates: IRelationshipTemplateModel[];
  onboardingProcess: IOnboardingStatus;
  approval?: IOnboardingApproval;
}

interface FetchClientGroupsParamsModel extends FetchParamsModel {
  search?: string;
  filter_status?: string;
  filter_review_status?: string;
}

export interface FetchDirectClientModel {
  type: AssociateTypes;
  organization?: IEntity;
  contact?: IContactModel;
}

export type RequestClientGroupUpdateBody = {
  status: 'invalid' | 'notReviewed';
  comment: string;
};

export interface ReviewDetailsNodeModel {
  details: {
    itemId: string;
    name: string;
    type: number;
  };
}

export interface ReviewDetailsNodeWithInvalidDocumentsModel
  extends ReviewDetailsNodeModel {
  invalidDocumentationItems: AnswerModel[];
}

export interface ReviewDetailsNodeWithExpiringDocumentsModel
  extends ReviewDetailsNodeModel {
  invalidExpiringDocuments: ExpiringDocument[];
}

export interface ReviewDetailsDocumentsModel<T = unknown> {
  nodes: T[];
}

export interface ReviewDetailsExpiringDocumentsModel {
  nodes: ReviewDetailsNodeModel[];
}

export interface ReviewDetailsStructureModel {
  message: string;
}

export interface ReviewDetailsChangeRequestCommentModel {
  comment: string;
  isValid: boolean;
}

export interface ReviewDetailsModel {
  type:
    | 'documents'
    | 'invalidClientDocumentationItems'
    | 'structure'
    | 'clientChangeRequestReviewItem'
    | 'expiringDocuments'
    | 'invalidPassportExpiringDocuments';
  documents?: ReviewDetailsDocumentsModel<ReviewDetailsNodeWithInvalidDocumentsModel>;
  structure?: ReviewDetailsStructureModel;
  expiringDocuments?: ReviewDetailsDocumentsModel<ReviewDetailsNodeWithExpiringDocumentsModel>;
  clientChangeRequestReviewItem?: ReviewDetailsChangeRequestCommentModel;
  invalidClientDocumentationItems?: AnswerModel[];
  invalidPassportExpiringDocuments?: ExpiringDocument[];
}

export interface FetchReviewDetailsModel {
  data: ReviewDetailsModel[];
}

export interface FetchMembersParams extends FetchParamsModel {
  search: string;
  type: string;
  has_online_access: boolean;
}

const clientGroupsAPI = {
  fetchClientGroups: (params: FetchClientGroupsParamsModel) => {
    return APIService.get<FetchResponseModel<IClientGroup[]>>(
      `${APIConfig.crmApi}/client-groups`,
      { params },
    ).then(({ data }) => data);
  },

  searchClientGroups: (params: FetchClientGroupsParamsModel) => {
    return APIService.get<FetchResponseModel<IClientGroupShortResponse>>(
      `${APIConfig.crmApi}/client-groups/search`,
      { params },
    );
  },

  fetchClientGroupById: (clientGroupId = 'my') => {
    return APIService.get<IClientGroupResponse>(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}`,
    ).then(({ data }) => data);
  },

  startApprovalWorkflow: (groupId: string) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-groups/${groupId}/start-approval-workflow`,
    );
  },

  postComplianceInformation: (body: INewComplianceBody) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-groups/${body.clientGroupId}/compliance-information`,
      body,
    );
  },

  requestStructureChartUpdate: (
    clientGroupId: string,
    body: RequestClientGroupUpdateBody,
  ) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/structure`,
      body,
    );
  },

  fetchMembers: (clientGroupId: string, params: FetchMembersParams) => {
    return APIService.get<FetchResponseModel<MemberModel>>(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/members`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchClientGroupUsers: (clientGroupId: string, params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<IClientGroupUser>>(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/users`,
      {
        params,
      },
    ).then(({ data }) => clientGroupsAdapter.fetchClientGroupUsers(data));
  },

  fetchDirectClient: (clientGroupId: number) => {
    return APIService.get<FetchDirectClientModel>(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/direct-client`,
    ).then(({ data }) => data);
  },

  fetchReviewDetails: (clientGroupId: string) => {
    return APIService.get<FetchReviewDetailsModel>(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/review-details`,
    ).then(({ data }) => data.data);
  },

  finishEditingStructureChart: (
    clientGroupId: string,
    data: { message: string },
  ) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/structure/enable`,
      data,
    ).then(({ data }) => data);
  },

  startEditingStructureChart: (clientGroupId: string) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/structure/disable`,
    ).then(({ data }) => data);
  },
};

export { clientGroupsAPI };
