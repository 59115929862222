import React, { memo, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import {
  ReviewDetailsModel,
  ReviewDetailsNodeWithExpiringDocumentsModel,
  ReviewDetailsNodeWithInvalidDocumentsModel,
} from '../../../../../api/crm/clientGroup/clientGroupsAPI';

// components
import Text from '../../../../DesignSystem/Core/Text';
import ReviewDetailsNodesTable from '../../../../Tables/TableTemplates/CRM/ReviewDetailsNodesTable';
import ReviewDetailsDocumentsTable from '../../../../Tables/TableTemplates/CRM/ReviewDetailsDocumentsTable';
import ReviewDetailsExpiringDocumentsTable from 'components/Tables/TableTemplates/CRM/ReviewDetailsExpiringDocumentsTable';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Divider, Typography } from 'antd';

interface IProps extends RequiredPropsForModalDialogModel {
  data: ReviewDetailsModel[] | null;
  title?: string;
  onSubmit: () => Promise<void>;
}

const RequireAdditionalInformationModalDialog = memo(
  ({ data, title, isVisible, closeCallback, onSubmit }: IProps) => {
    const { t } = useTranslation(['crm', 'common']);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
      setLoading(true);
      await onSubmit();
      setLoading(false);
      closeCallback();
    };

    const renderReviewDetails = useMemo(() => {
      if (!data) {
        return null;
      }

      return data.map((reviewDetails) => {
        switch (reviewDetails.type) {
          case 'structure': {
            return (
              <>
                <StyledText variant="h5">
                  {t(
                    `client_group.approval.review_details_modal.review_types.${reviewDetails.type}`,
                  )}
                </StyledText>
                <StyledDivider />
                <Typography.Paragraph>
                  {reviewDetails.structure?.message}
                </Typography.Paragraph>
                <StyledDescription>
                  {t(
                    'client_group.approval.review_details_modal.structure.message_description',
                  )}
                </StyledDescription>
              </>
            );
          }

          case 'clientChangeRequestReviewItem': {
            return (
              <>
                <StyledText variant="h5">
                  {t(
                    `client_group.approval.review_details_modal.review_types.${reviewDetails.type}`,
                  )}
                </StyledText>
                <StyledDivider />
                <Typography.Paragraph>
                  {reviewDetails?.clientChangeRequestReviewItem?.comment}
                </Typography.Paragraph>
              </>
            );
          }

          case 'invalidClientDocumentationItems': {
            return (
              <>
                <StyledText variant="h5">
                  {t(
                    `client_group.approval.review_details_modal.review_types.${reviewDetails.type}`,
                  )}
                </StyledText>
                <StyledDivider />
                <ReviewDetailsDocumentsTable
                  data={reviewDetails.invalidClientDocumentationItems || []}
                />
              </>
            );
          }

          case 'documents': {
            return (
              <>
                <StyledText variant="h5">
                  {t(
                    `client_group.approval.review_details_modal.review_types.${reviewDetails.type}`,
                  )}
                </StyledText>
                <StyledDivider />
                <ReviewDetailsNodesTable
                  data={reviewDetails.documents?.nodes || []}
                  expandable={{
                    expandedRowRender: (
                      data: ReviewDetailsNodeWithInvalidDocumentsModel,
                    ) => (
                      <ReviewDetailsDocumentsTable
                        data={data.invalidDocumentationItems}
                      />
                    ),
                  }}
                />
              </>
            );
          }

          case 'expiringDocuments': {
            return (
              <>
                <StyledText variant="h5">
                  {t(
                    `client_group.approval.review_details_modal.review_types.${reviewDetails.type}`,
                  )}
                </StyledText>
                <StyledDivider />
                <ReviewDetailsNodesTable
                  data={reviewDetails.expiringDocuments?.nodes || []}
                  expandable={{
                    expandedRowRender: (
                      data: ReviewDetailsNodeWithExpiringDocumentsModel,
                    ) => (
                      <ReviewDetailsExpiringDocumentsTable
                        data={data.invalidExpiringDocuments}
                      />
                    ),
                  }}
                />
              </>
            );
          }

          case 'invalidPassportExpiringDocuments': {
            return (
              <>
                <StyledText variant="h5">
                  {t(
                    `client_group.approval.review_details_modal.review_types.${reviewDetails.type}`,
                  )}
                </StyledText>
                <StyledDivider />
                <ReviewDetailsExpiringDocumentsTable
                  data={reviewDetails.invalidPassportExpiringDocuments || []}
                />
              </>
            );
          }
        }
      });
    }, [data]);

    return (
      <ModalDialog
        submitButtonProps={{
          text: t('continue', { ns: 'common' }),
          onClick: handleSubmit,
          loading,
        }}
        width={1650}
        title={title}
        isVisible={isVisible}
        closeCallback={closeCallback}
      >
        {renderReviewDetails}
      </ModalDialog>
    );
  },
);

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colorLight};
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.marginXs} 0;
`;

const StyledDescription = styled(Typography.Text)`
  color: ${({ theme }) => theme.warningColor};
`;

export default RequireAdditionalInformationModalDialog;
