import React, { useMemo } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useUserAccess from '../../../../hooks/useUserAccess';
import useTranslation from '../../../../hooks/useTranslation';
import reviewConfigurationAPI from '../../../../api/compliance/reviewConfigurationAPI';
import reviewConfigurationAdapter from '../../../../apiAdapters/compliance/reviewConfigurationAdapter';
import { message } from 'antd';

// components
import ReviewConfigurationRuleModal, {
  FormValuesModel,
} from '../../../../components/Modals/TemplateModalDialogs/Compliance/ReviewConfigurationRuleModal';

interface IProps {
  isVisible: boolean;
  closeCallback: (wasAdded?: boolean) => void;
  ruleId: string | null;
}

const ViewEditReviewConfigurationRuleModal = ({
  isVisible,
  closeCallback,
  ruleId,
}: IProps) => {
  const { t } = useTranslation('compliance');
  const { response, loading } = useFetch(
    () =>
      ruleId
        ? reviewConfigurationAPI.fetchReviewConfigurationRuleById(ruleId)
        : null,
    [ruleId],
  );
  const [hasAccessToUpdate] = useUserAccess([
    'Compliance_ReviewConfiguration_Update',
  ]);

  const modalTitle = useMemo(() => {
    return hasAccessToUpdate
      ? t('review_configuration.update_review_configuration_rule')
      : t('review_configuration.view_review_configuration_rule');
  }, [hasAccessToUpdate]);

  const initialFormValues: FormValuesModel | null = useMemo(() => {
    if (!response) {
      return null;
    }

    return {
      isEditMode: true,
      reviewType: response.reviewType,
      riskLevel: response.riskLevel,
      referenceObject: response.referenceObject.id,
      referenceProperty: response.referencePropertyName,
      comparisonOperator: response.comparationOperator,
      expectedDataType: response.expectedDataType,
      expectedValue: JSON.parse(response.expected.valueJSON),
      expectedValueType: response.expected.type,
      isManualReview: response.isManualReview,
      state: response.state,
      initialReferenceObject: { ...response.referenceObject },
    };
  }, [response]);

  const handleOnSubmit = async (values: FormValuesModel) => {
    const formattedRule =
      reviewConfigurationAdapter.editReviewConfigurationRule(values);
    await reviewConfigurationAPI.editReviewConfigurationRule(
      ruleId as string,
      formattedRule,
    );
    message.success(t('review_configuration.success_edit'));
    closeCallback(true);
  };

  return (
    <ReviewConfigurationRuleModal
      mode={hasAccessToUpdate ? 'update' : 'view'}
      title={modalTitle}
      isVisible={isVisible}
      onSubmit={handleOnSubmit}
      closeCallback={closeCallback}
      initialFormValues={initialFormValues}
      loadingInitialData={loading}
    />
  );
};

export default ViewEditReviewConfigurationRuleModal;
