import { DateHelpers } from '@helpers/date';
import { EntityHelpers } from '@helpers/crm/entity';
import { IContactModel } from '../../../typings/crm/contact';
import { generateUniqId } from '@helpers/utils';
import { ExpiringDocument } from 'api/crm/expiringDocuments/expiringDocumentsAPI';
import { ISocialMediaModel } from 'typings/crm/entity';
import { YYYYMMDD_TIME_FORMAT } from 'constants/global';
import { FormValuesModel as ContactGeneralFormFieldsModel } from 'components/Forms/TemplateForms/CRM/ContactKYCForms/GeneralForm';
import { FormValuesModel as ContactDetailsFormValuesModel } from '../../../components/Forms/TemplateForms/CRM/ContactDetailsForm';
import { FormValuesModel as ContactIdentificationFormValuesModel } from '../../../components/Forms/TemplateForms/CRM/ContactIdentificationForm';
import { FormValuesModel as ContactDetailsWithAddressesFormValuesModel } from '../../../components/Forms/TemplateForms/CRM/ContactDetailsWithIdentificationForm';
import {
  ContactDataQuery,
  CreateContactDataQuery,
  ContactIdentificationDataQuery,
} from 'api/crm/contact/contactsAPI';
import {
  EmailType,
  GenderOptions,
  PhoneNumberType,
  SocialMedia,
  SourceOfWealthCategories,
} from 'enums/crm/crm';

const contactsAdapter = {
  formatContactDataFromFormToQuery: (
    formValues: ContactDetailsFormValuesModel,
  ): ContactDataQuery => {
    return {
      firstName: formValues.firstName.trim(),
      middleName: formValues.middleName.trim(),
      lastName: formValues.lastName.trim(),
      gender: formValues.gender,
      dateOfBirth: formValues.dateOfBirth
        ? DateHelpers.formatDateToString(
            formValues.dateOfBirth,
            YYYYMMDD_TIME_FORMAT,
          )
        : null,
      countryOfBirth: formValues.countryOfBirth,
      nationality: formValues.nationality,
      isInvitationPermitted: formValues.isInvitationPermitted,
      isVendor: formValues.isVendor,
      sourceOfWealthCategories: formValues.sourceOfWealthCategories,
      sourceOfWealthDescription: formValues.sourceOfWealthDescription,
      emails: formValues.emails.map((e) => ({
        type: e.type as EmailType,
        address: e.address,
        isPrimary: e.id === formValues.primary.email,
      })),
      phoneNumbers: formValues.phoneNumbers.map((e) => ({
        type: e.type as PhoneNumberType,
        number: e.number,
        isPrimary: e.id === formValues.primary.phoneNumber,
      })),
      socialMedia: EntityHelpers.getSocialMediaData(formValues.socialMedia),
    };
  },

  formatContactWithIdentificationDataFromFormToQuery: (
    formValues: ContactDetailsWithAddressesFormValuesModel,
  ): CreateContactDataQuery => {
    const contactData =
      contactsAdapter.formatContactDataFromFormToQuery(formValues);
    const identificationData =
      contactsAdapter.formatContactIdentificationDataFromFormToQuery(
        formValues,
      );
    return {
      ...contactData,
      ...identificationData,
    };
  },

  formatContactDataFromQueryToForm: (
    contact: IContactModel,
    hasAssociationWithAuthUser?: boolean,
  ): ContactDetailsFormValuesModel => {
    const primaryEmail = contact.emails.find((e) => e.isPrimary);
    const primaryPhone = contact.phoneNumbers.find((e) => e.isPrimary);
    const primaryAddress = contact.addresses.find((e) => e.isPrimary);

    const primary = {
      email: primaryEmail ? generateUniqId() : null,
      address: primaryAddress ? primaryAddress._id || null : null,
      phoneNumber: primaryPhone ? generateUniqId() : null,
    };

    return {
      hasAssociationWithAuthUser: !!hasAssociationWithAuthUser,
      firstName: contact.firstName || '',
      middleName: contact.middleName || '',
      lastName: contact.lastName || '',
      gender: contact.gender || null,
      dateOfBirth: contact.dateOfBirth
        ? DateHelpers.formatDateToUTC(contact.dateOfBirth)
        : null,
      countryOfBirth: contact.countryOfBirth || null,
      nationality: contact.nationality || [],
      isInvitationPermitted:
        typeof contact.isInvitationPermitted === 'boolean'
          ? contact.isInvitationPermitted
          : null,
      isVendor: typeof contact.isVendor === 'boolean' ? contact.isVendor : null,
      sourceOfWealthCategories:
        (contact.sourceOfWealthCategories as SourceOfWealthCategories[]) ||
        null,
      sourceOfWealthDescription: contact.sourceOfWealthDescription || '',
      primary,
      emails: contact.emails.map((e) => ({
        id: e.isPrimary ? primary.email : generateUniqId(),
        type: e.type,
        address: e.address,
        primary: { status: e.isPrimary },
      })),
      phoneNumbers: contact.phoneNumbers.map((e) => ({
        id: e.isPrimary ? primary.phoneNumber : generateUniqId(),
        type: e.type,
        number: e.number,
        primary: { status: e.isPrimary },
      })),
      socialMedia: {
        twitterLink: contact.socialMedia
          ? EntityHelpers.getSocialMediaLink(
              SocialMedia.Twitter,
              contact.socialMedia,
            )
          : '',
        facebookLink: contact.socialMedia
          ? EntityHelpers.getSocialMediaLink(
              SocialMedia.Facebook,
              contact.socialMedia,
            )
          : '',
        linkedInLink: contact.socialMedia
          ? EntityHelpers.getSocialMediaLink(
              SocialMedia.LinkedIn,
              contact.socialMedia,
            )
          : '',
      },
    };
  },

  formatContactIdentificationDataFromQueryToForm: (
    contact: IContactModel,
    passports?: ExpiringDocument[],
  ): ContactIdentificationFormValuesModel => {
    const primaryAddress = contact.addresses.find((e) => e.isPrimary);
    const canUpdatePrimaryStatusForAddresses = !contact.addresses.some(
      (e) => e.approval?.isApproved,
    );

    return {
      isPEP: typeof contact.isPEP === 'boolean' ? contact.isPEP : null,
      pepInformation: contact.pepInformation || '',
      isRegulated:
        typeof contact.isRegulated === 'boolean' ? contact.isRegulated : null,
      regulationCountry: contact.regulationCountry || null,
      primaryAddressId: primaryAddress ? primaryAddress._id || null : null,
      canUpdatePrimaryStatusForAddresses,
      addresses: contact.addresses.map((e) => ({
        id: e._id as string,
        type: e.type || [],
        country: e.country || '',
        city: e.city || '',
        street: e.street || '',
        state: e.state || '',
        postal: e.postalCode,
        isApproved: !!e.approval?.isApproved,
        isCreated: true,
      })),

      passports: passports
        ? passports.map((e) => ({
            id: e._id,
            number: e.details.passportDetails.number || '',
            country: e.details.passportDetails.issuingCountry || '',
            issuedAt: e.details.passportDetails.issuedAt
              ? DateHelpers.formatDateToUTC(e.details.passportDetails.issuedAt)
              : null,
            expirationDate: e.expirationDate
              ? DateHelpers.formatDateToUTC(e.expirationDate)
              : null,
            documents: e.details.passportDetails.documents.map((doc) => ({
              name: doc.files[0].name,
              fileId: doc.files[0].id,
              documentId: doc.id,
            })),
          }))
        : undefined,
    };
  },

  formatContactIdentificationDataFromFormToQuery: (
    formValues: ContactIdentificationFormValuesModel,
  ): ContactIdentificationDataQuery => {
    const filteredAddresses = formValues.addresses.filter((e) => !e.isApproved);

    return {
      isPEP: formValues.isPEP,
      pepInformation: formValues.pepInformation.trim(),
      isRegulated: formValues.isRegulated,
      regulationCountry: formValues.regulationCountry,
      addresses: filteredAddresses.map((e) => ({
        id: e.isCreated ? e.id : undefined,
        type: e.type,
        country: e.country,
        city: e.city,
        street: e.street,
        state: e.state,
        postalCode: e.postal,
        isPrimary: e.id === formValues.primaryAddressId,
      })),
    };
  },

  formatKYCContactGeneralDataFromQueryToFormFieldsModel: (contact: {
    firstName: string;
    middleName: string;
    lastName: string;
    gender: GenderOptions | null;
    dateOfBirth: Date | string | null;
    countryOfBirth: string;
    emails: { type: EmailType; address: string; isPrimary: boolean }[];
    phoneNumbers: {
      type: PhoneNumberType;
      number: string;
      isPrimary: boolean;
    }[];
    socialMedia: ISocialMediaModel[];
  }): ContactGeneralFormFieldsModel => {
    return {
      firstName: contact?.firstName || '',
      middleName: contact?.middleName || '',
      lastName: contact?.lastName || '',
      gender: contact?.gender || null,
      dateOfBirth: contact?.dateOfBirth
        ? DateHelpers.formatDateToUTC(contact.dateOfBirth)
        : null,
      countryOfBirth: contact?.countryOfBirth || '',
      emails: contact?.emails
        ? contact.emails.map((e) => ({
            id: e.isPrimary ? e.address : generateUniqId(),
            type: e.type,
            address: e.address,
            primary: { status: e.isPrimary },
          }))
        : [],
      phoneNumbers: contact?.phoneNumbers
        ? contact.phoneNumbers.map((e) => ({
            id: e.isPrimary ? e.number : generateUniqId(),
            type: e.type,
            number: e.number,
            primary: { status: e.isPrimary },
          }))
        : [],
      socialMedia: {
        twitterLink: contact?.socialMedia
          ? EntityHelpers.getSocialMediaLink(
              SocialMedia.Twitter,
              contact.socialMedia,
            )
          : '',
        facebookLink: contact?.socialMedia
          ? EntityHelpers.getSocialMediaLink(
              SocialMedia.Facebook,
              contact.socialMedia,
            )
          : '',
        linkedInLink: contact?.socialMedia
          ? EntityHelpers.getSocialMediaLink(
              SocialMedia.LinkedIn,
              contact.socialMedia,
            )
          : '',
      },
    };
  },
};

export { contactsAdapter };
