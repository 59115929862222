import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import groupsAPI from '../../../../api/orac/groupsAPI';

// components
import { Button, message } from 'antd';
import AddEditGroupModal, {
  FormValuesModel,
} from '../../../../components/Modals/TemplateModalDialogs/ORAC/Groups/AddEditGroupModal';

interface IProps {
  onAdd?: () => void;
}

const AddGroup = ({ onAdd }: IProps) => {
  const { t } = useTranslation('orac');

  const [isModalVisible, setVisibleModal] = useState(false);

  const initialFormValues: FormValuesModel = useMemo(
    () => ({ name: '', description: '' }),
    [],
  );

  const handleSubmit = async (values: FormValuesModel) => {
    await groupsAPI.createNewGroup(values);
    message.success(t('groups.add.success'));
    closeCallback(true);
  };

  const closeCallback = (wasAdded?: boolean) => {
    if (wasAdded) {
      onAdd && onAdd();
    }
    setVisibleModal(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisibleModal(true)}>
        {t('groups.add.title')}
      </Button>

      <AddEditGroupModal
        title={t('groups.add.title')}
        onSubmit={handleSubmit}
        isVisible={isModalVisible}
        closeCallback={closeCallback}
        initialFormValues={initialFormValues}
      />
    </>
  );
};

export default AddGroup;
