import React, { useEffect } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import { CrmItemModel } from '../../../../../../../../../api/crm/relationship/relationshipAPI';
import { IAccountModel } from '../../../../../../../../../typings/finance/account';
import { currenciesAPI } from '../../../../../../../../../api/finance/currency/currenciesAPI';
import { AccountsHelpers } from '../../../../../../../../../helpers/finance/accounts';
import { JournalFormValues } from '../../../..';
import { FORMAT_AMOUNT_REG } from '../../../../../../../../../constants/regularExpressions';
import { DEFAULT_DATE_FORMAT } from '../../../../../../../../../constants/global';
import { useField, useFormikContext } from 'formik';
import { JournalEntryModel, JournalModel } from '../..';

// components
import FormField from '@core_components/FormField';
import AmountField from '../../../../../../../../Forms/FormComponents/AmountField';
import DocumentsField from 'components/Forms/FormComponents/DocumentsField';
import ResidentSelect from 'components/Forms/FormComponents/SelectInputs/ResidentSelect';
import EllipsisTooltip from '../../../../../../../../Tooltips/EllipsisTooltip';
import AccountAutocomplete from '../../../../../../../../Forms/FormComponents/Autocompletes/Finance/AccountAutocomplete';
import RelationshipEntryAutocomplete from '../../../../../../../../Forms/FormComponents/Autocompletes/CRM/RelationshipEntryAutocomplete';
import { DeleteOutlined } from '@ant-design/icons';
import { Checkbox, Input, Typography } from 'antd';

interface JournalEntryCellProps extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  index: number;
  record: any;
  onDelete: (index: number) => void;
  dataIndex: string;
  journalEntriesLength: number;
  isEditMode: boolean;
  isEditActive: boolean;
  fieldName: keyof JournalModel;
}

const JournalEntry = ({
  index,
  record,
  onDelete,
  isEditMode,
  isEditActive,
  dataIndex,
  disabled,
  fieldName,
  journalEntriesLength,
}: JournalEntryCellProps) => {
  let result = null;

  const [field, , helpers] = useField<JournalEntryModel>(
    `${fieldName}.${index}`,
  );

  const { values } = useFormikContext<JournalFormValues>();

  const canDelete = journalEntriesLength > 2;

  useEffect(() => {
    if (field.value.debitAmount) {
      helpers.setValue({ ...field.value, creditAmount: undefined });
    }
  }, [field.value.debitAmount]);

  useEffect(() => {
    if (field.value.creditAmount) {
      helpers.setValue({ ...field.value, debitAmount: undefined });
    }
  }, [field.value.creditAmount]);

  const handleAccountSelect = async (account: any) => {
    const newFieldValue = {
      ...field.value,
      account: { ...account, isoCode: account.currencyCode },
      accountNumber: account.accountNumber,
    };
    helpers.setValue(newFieldValue);

    const currencyRate = await currenciesAPI.getCurrencyRateHome(
      account.currencyId,
    );

    // TODO: Remove workaround when currencies API will be fixed
    helpers.setValue({
      ...newFieldValue,
      currencyRate: currencyRate === 1 ? currencyRate : currencyRate / 10000,
    });
  };

  const handleRelatedPartySelect = (id: string, item: CrmItemModel) => {
    helpers.setValue({
      ...field.value,
      relatedPartyId: id,
      relatedPartyType: item.type,
    });
  };

  const renderAccountNumber = () => {
    const { accountNumber, accountName = '', account } = field.value;

    if (!disabled) {
      return (
        <FormField
          name={`${field.name}.accountNumber`}
          component={AccountAutocomplete}
          shouldShowErrorMessage={false}
          additionalProps={{
            allowClear: true,
            onSelect: (_: string, account: IAccountModel) =>
              handleAccountSelect(account),
            isFullAccountNumber: true,
          }}
        />
      );
    }
    return (
      <Typography.Text>
        {AccountsHelpers.generateAccountFullName(
          accountName,
          accountNumber,
          account?.isoCode as string,
        )}
      </Typography.Text>
    );
  };

  const renderDescription = () => {
    const { description } = field.value;

    if (!disabled || values.isEditMode) {
      return (
        <FormField
          name={`${field.name}.description`}
          component={Input}
          shouldShowErrorMessage={false}
        />
      );
    }
    return (
      <EllipsisTooltip maxTextContainerWidth="240px" title={description}>
        {description}
      </EllipsisTooltip>
    );
  };

  switch (dataIndex) {
    case 'index':
      result = <StyledText>{index + 1}</StyledText>;
      break;

    case 'accountNumber':
      result = renderAccountNumber();
      break;

    case 'financialTypeName':
      result = record.account ? record.account.financialTypeName : undefined;
      break;

    case 'debitAmount':
      result = (
        <FormField
          disabled={disabled}
          component={StyledAmountField}
          shouldShowErrorMessage={false}
          name={`${field.name}.debitAmount`}
        />
      );
      break;

    case 'creditAmount':
      result = (
        <FormField
          disabled={disabled}
          component={StyledAmountField}
          shouldShowErrorMessage={false}
          name={`${field.name}.creditAmount`}
        />
      );
      break;

    case 'currencyCode':
      result = record.account ? record.account.isoCode : undefined;
      break;

    case 'currencyRate':
      result =
        record.currencyRate &&
        record.currencyRate.toLocaleString(undefined, {
          maximumFractionDigits: 4,
          minimumFractionDigits: 2,
        });
      break;

    case 'debitsHome':
      {
        if (isEditMode) {
          result = record.debitHome
            ? AccountsHelpers.formatMoneyToString(
                AccountsHelpers.roundNumber(record.debitHome, 2).toFixed(2),
              )
            : undefined;
        } else {
          result =
            record.debitAmount && record.currencyRate
              ? String(
                  AccountsHelpers.roundNumber(
                    record.debitAmount * record.currencyRate,
                    2,
                  ).toFixed(2),
                ).replace(FORMAT_AMOUNT_REG, ',')
              : undefined;
        }
      }
      break;

    case 'creditsHome':
      {
        if (isEditMode) {
          result = record.creditHome
            ? AccountsHelpers.formatMoneyToString(
                AccountsHelpers.roundNumber(record.creditHome, 2).toFixed(2),
              )
            : undefined;
        } else {
          result =
            record.creditAmount && record.currencyRate
              ? String(
                  AccountsHelpers.roundNumber(
                    record.creditAmount * record.currencyRate,
                    2,
                  ).toFixed(2),
                ).replace(FORMAT_AMOUNT_REG, ',')
              : undefined;
        }
      }
      break;

    case 'journalPostingDate':
      result = moment
        .unix(record.journalPostingDate)
        .format(DEFAULT_DATE_FORMAT);
      break;

    case 'description':
      result = renderDescription();
      break;

    case 'relatedPartyId':
      result = (
        <FormField
          name={`${field.name}.relatedPartyId`}
          component={RelationshipEntryAutocomplete}
          shouldShowErrorMessage={false}
          disabled={disabled && !values.isEditMode}
          additionalProps={{
            allowClear: true,
            initialValue: field.value.relatedPartyInitialOption,
            isVendor: true,
            includeClientGroups: true,
            onSelect: handleRelatedPartySelect,
          }}
        />
      );
      break;

    case 'journalDocuments':
      {
        result = (
          <FormField
            name={`${field.name}.documents`}
            component={DocumentsField}
            shouldShowErrorMessage={false}
            disabled={disabled}
          />
        );
      }
      break;

    case 'isResident': {
      result = (
        <FormField
          name={`${field.name}.isResident`}
          component={ResidentSelect}
          shouldShowErrorMessage={false}
          disabled={disabled && !values.isEditMode}
        />
      );
      break;
    }

    case 'isVisibleForClient': {
      result = (
        <StyledFormField
          name={`${field.name}.${dataIndex}`}
          component={Checkbox}
          shouldShowErrorMessage={false}
          disabled={disabled && !isEditActive}
        />
      );
      break;
    }

    case 'delete':
      result = canDelete && !disabled && (
        <StyledDeleteIcon onClick={() => onDelete(index)} />
      );
      break;

    default:
      return '';
  }

  return <td>{result}</td>;
};

const StyledDeleteIcon = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.errorColor};
  cursor: pointer;
`;

const StyledText = styled(Typography.Text)`
  display: flex;
  justify-content: center;
`;

const StyledAmountField = styled(AmountField)`
  width: 100%;
`;

const StyledFormField = styled(FormField)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default JournalEntry;
