import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { IContactModel } from '../../../typings/crm/contact';
import { GenderOptions, SocialMedia } from 'enums/crm/crm';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';

export type ContactDataQuery = {
  firstName: string;
  lastName: string;
  middleName: string;
  gender: GenderOptions | null;
  dateOfBirth: string | null;
  countryOfBirth: string | null;
  nationality: string[] | null;
  sourceOfWealthCategories: string[] | null;
  sourceOfWealthDescription: string;
  isVendor: boolean | null;
  isInvitationPermitted: boolean | null;
  emails: { type: string; address: string; isPrimary: boolean }[];
  phoneNumbers: { type: string; number: string; isPrimary: boolean }[];
  socialMedia: { type: SocialMedia; path: string }[];
};

export type ContactIdentificationDataQuery = {
  isRegulated: boolean | null;
  regulationCountry: string[] | null;
  isPEP: boolean | null;
  pepInformation: string;
  addresses:
    | {
        city: string;
        country: string;
        isPrimary: boolean;
        postalCode: string;
        type: string[];
        street: string;
        state: string;
      }[]
    | null;
};

export interface CreateContactDataQuery
  extends ContactDataQuery,
    ContactIdentificationDataQuery {}

interface FetchContactsParamsModel extends FetchParamsModel {
  search?: string;
  isAssociated?: boolean;
}

interface PhoneNumberValidationResponseModel {
  data: {
    phoneNumber: string;
    isValid: boolean;
  }[];
}

const contactsAPI = {
  fetchContacts: (params: FetchContactsParamsModel) => {
    return APIService.get<FetchResponseModel<IContactModel>>(
      `${APIConfig.crmApi}/contacts`,
      { params },
    ).then(({ data }) => data);
  },

  findContact: (associationId: string) => {
    return APIService.get<IContactModel>(`${APIConfig.crmApi}/contacts/find`, {
      params: { associationId },
    }).then(({ data }) => data);
  },

  createContact: (data: CreateContactDataQuery) => {
    return APIService.post<IContactModel>(
      `${APIConfig.crmApi}/contacts`,
      data,
    ).then(({ data }) => data);
  },

  editContact: (contactId: string, data: ContactDataQuery) => {
    return APIService.patch<IContactModel>(
      `${APIConfig.crmApi}/contacts/${contactId}`,
      data,
    ).then(({ data }) => data);
  },

  editContactIdentificationDataByContactId: (
    contactId: string,
    data: ContactIdentificationDataQuery,
  ) => {
    return APIService.patch<IContactModel>(
      `${APIConfig.crmApi}/contacts/${contactId}/identifications`,
      data,
    ).then(({ data }) => data);
  },

  fetchContactById: (contactId: string) => {
    return APIService.get<IContactModel>(
      `${APIConfig.crmApi}/contacts/${contactId}`,
    ).then(({ data }) => data);
  },

  deleteContactById: (contactId: string, deleteReason: string) => {
    return APIService.delete(`${APIConfig.crmApi}/contacts/${contactId}`, {
      data: { reason: deleteReason },
    }).then(({ data }) => data);
  },

  fetchContactComplianceDataById: (contactId: string) => {
    return APIService.get<{
      lastReviewDate: string;
      dateOfCompletion: string;
      lastClientReviewDate: string;
    }>(`${APIConfig.crmApi}/contacts/${contactId}/compliance`).then(
      ({ data }) => data,
    );
  },

  validatePhoneNumbers: (phoneNumbers: string[]) => {
    return APIService.post<PhoneNumberValidationResponseModel>(
      `${APIConfig.crmApi}/phone/validate`,
      phoneNumbers,
    ).then(({ data }) => data);
  },
};

export { contactsAPI };
