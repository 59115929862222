import React from 'react';

// helpers
import useTranslation from '../../../hooks/useTranslation';
import { DeleteEntityValidationSchema } from '../../../validations/crm/entities';

// components
import Form from '@core_components/Form';
import FormField from '@core_components/FormField';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Input } from 'antd';

interface IProps extends RequiredPropsForModalDialogModel {
  title?: string;
  description?: string;
  submitBtnText?: string;
  isLoading: boolean;
  isSubmitButtonDanger?: boolean;
  onSubmitCallback: (reason: string) => void;
}

interface IFormValues {
  reason: string;
}

const DeleteConfirmation = ({
  isVisible,
  closeCallback,
  isLoading,
  onSubmitCallback,
  title,
  description,
  submitBtnText,
  isSubmitButtonDanger = true,
}: IProps) => {
  const { t } = useTranslation(['common', 'crm', 'form']);

  const handleSubmit = async (values: IFormValues) => {
    await onSubmitCallback(values.reason);
  };

  return (
    <Form<IFormValues>
      initialValues={{ reason: '' }}
      enableReinitialize
      validationSchema={DeleteEntityValidationSchema}
      onSubmit={handleSubmit}
      renderForm={() => (
        <ModalDialog
          width={520}
          title={title || t('modal.deleteConfirmation.title')}
          isVisible={isVisible}
          closeCallback={() => closeCallback()}
          submitButtonProps={{
            danger: isSubmitButtonDanger,
            text: submitBtnText || t('delete'),
          }}
        >
          <FormField
            label={
              description || t('contacts.new.deleteMessage', { ns: 'crm' })
            }
            name="reason"
            component={Input.TextArea}
            disabled={isLoading}
            placeholder={t('enterPlaceholder', {
              ns: 'form',
              autoSize: { minRows: 6, maxRows: 6 },
              fieldName: t('contacts.new.reason', { ns: 'crm' }).toLowerCase(),
            })}
          />
        </ModalDialog>
      )}
    />
  );
};

export default DeleteConfirmation;
