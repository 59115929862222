import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { useFormikContext } from 'formik';

// components
import Button from '@core_components/Button';
import { Col, Row } from 'antd';

export type FormValuesModel = {
  submitActionType:
    | 'save'
    | 'start-approval-workflow'
    | 'require-additional-info'
    | null;
};

const ActionButtons = () => {
  const { t } = useTranslation(['crm', 'common']);
  const { values, setFieldValue, submitForm, isSubmitting } =
    useFormikContext<FormValuesModel>();

  const handleSave = async () => {
    await setFieldValue('submitActionType', 'save');
    submitForm();
  };

  const handleRequireAdditionalInfo = async () => {
    await setFieldValue('submitActionType', 'require-additional-info');
    submitForm();
  };

  const handleStartApprovalWorkflow = async () => {
    await setFieldValue('submitActionType', 'start-approval-workflow');
    submitForm();
  };

  return (
    <StyledRow gutter={[16, 16]} justify="end">
      <Col>
        <StyledButton
          size="large"
          type="bordered"
          disabled={isSubmitting}
          loading={isSubmitting && values.submitActionType === 'save'}
          onClick={handleSave}
        >
          {t('save', { ns: 'common' })}
        </StyledButton>
      </Col>
      <Col>
        <StyledButton
          size="large"
          type="bordered"
          disabled={isSubmitting}
          loading={
            isSubmitting &&
            values.submitActionType === 'require-additional-info'
          }
          onClick={handleRequireAdditionalInfo}
        >
          {t('client_group.approval.require_more_info')}
        </StyledButton>
      </Col>
      <Col>
        <StyledButton
          size="large"
          disabled={isSubmitting}
          loading={
            isSubmitting &&
            values.submitActionType === 'start-approval-workflow'
          }
          onClick={handleStartApprovalWorkflow}
        >
          {t('client_group.approval.start_approval')}
        </StyledButton>
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginMd};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default ActionButtons;
