import React, { useMemo } from 'react';

// helpers
import usersAPI from '../../../../api/orac/usersAPI';
import usersAdapter from '../../../../apiAdapters/orac/usersAdapter';
import useTranslation from '../../../../hooks/useTranslation';
import { message } from 'antd';
import { UserTypes } from 'enums/admin/orac';
import { NOTIFICATION_DURATION } from '../../../../constants/global';
import { NewUserValidationSchema } from '../../../../validations/orac/users';

// components
import Form from '@core_components/Form';
import UserForm, { ICreateUserForm } from './InnerForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

type IProps = RequiredPropsForModalDialogModel;

const AddUserModal = ({ isVisible, closeCallback }: IProps) => {
  const { t } = useTranslation('orac');

  const handleSubmit = async (values: ICreateUserForm) => {
    await usersAPI.createUser(usersAdapter.createUser(values));
    message.success(t('users.add.success'), NOTIFICATION_DURATION);
  };

  const initialValues = useMemo<ICreateUserForm | null>(() => {
    return {
      type: UserTypes.Admin,
      contactId: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    };
  }, []);

  return (
    <Form<ICreateUserForm>
      initialValues={isVisible ? initialValues : null}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={NewUserValidationSchema}
      confirmExitWithoutSaving
      renderForm={
        <ModalDialog
          title={t('users.add.title')}
          width={900}
          isVisible={isVisible}
          closeCallback={() => closeCallback()}
        >
          <UserForm />
        </ModalDialog>
      }
    />
  );
};

export default AddUserModal;
