import React, { memo, useCallback, useMemo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { contactsAPI } from 'api/crm/contact/contactsAPI';
import { IContactModel } from 'typings/crm/contact';
import { contactsAdapter } from 'apiAdapters/crm/contact/contactsAdapter';
import { expiringDocumentsAPI } from 'api/crm/expiringDocuments/expiringDocumentsAPI';
import { ContactIdentificationValidationSchema } from 'validations/crm/contacts';

// components
import Form from '@core_components/Form';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import FormEditModeButtons from '@common_components/Form/FormEditModeButtons';
import ContactIdentificationForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/CRM/ContactIdentificationForm';

interface IProps {
  contact: IContactModel;
  updateContactData: () => void;
}

const Identification = memo(({ contact, updateContactData }: IProps) => {
  const { t } = useTranslation('crm');
  const [isDisabled, setIsDisabled] = useState(true);
  const [hasUpdateAccess] = useUserAccess([
    'CRM_Contacts_IdentificationTab_Update',
  ]);

  const { response, loading } = useFetch(
    () =>
      expiringDocumentsAPI.fetchExpiringDocuments({
        entityIds: contact._id,
        page: 1,
        limit: 100,
      }),
    [contact],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!response) {
      return null;
    }

    return {
      displayPassports: true,
      ...contactsAdapter.formatContactIdentificationDataFromQueryToForm(
        contact,
        response.data,
      ),
    };
  }, [contact, response]);

  const handleSubmit = useCallback(
    async (values) => {
      const formattedBody =
        contactsAdapter.formatContactIdentificationDataFromFormToQuery(values);
      await contactsAPI.editContactIdentificationDataByContactId(
        contact._id,
        formattedBody,
      );
      message.success(t('contacts.new.editSuccess'));
      updateContactData();
      setIsDisabled(true);
    },
    [contact, updateContactData],
  );

  return (
    <LoadingWrapper loading={loading}>
      {initialFormValues && (
        <Form
          disabled={isDisabled}
          enableReinitialize
          confirmExitWithoutSaving
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
          validationSchema={ContactIdentificationValidationSchema}
          renderForm={
            <>
              {hasUpdateAccess && (
                <FormEditModeButtons
                  isDisabled={isDisabled}
                  setDisabledStatus={setIsDisabled}
                />
              )}
              <ContactIdentificationForm />
            </>
          }
        />
      )}
    </LoadingWrapper>
  );
});

export default Identification;
