import React, { memo, useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import useTranslation from '../../../../../../hooks/useTranslation';
import { message } from 'antd';
import { TransferTemplateTypes } from '../../../../../../enums/finance/transferTemplates';
import { TransferTemplateStatuses } from 'enums/transfers/templates';
import { transferTemplatesAPIAdapter } from '../../../../../../apiAdapters/finance/transferTemplates/transferTemplatesAPIAdapter';
import {
  InternalTransferTemplateFromQuery,
  WireTransferTemplateFromQuery,
  transferTemplatesAPI,
} from '../../../../../../api/transferTemplates/transferTemplatesAPI';

// components
import Button from '@core_components/Button';
import LoadingWrapper from '../../../../../WrapperComponents/LoadingWrapper';
import DeleteConfirmation from 'components/Modals/DeleteConfirmation';
import WireTransferTemplateInfo from '../../../../../Additional/DetailsSections/Accounting/WireTransferTemplateInfo';
import InternalTransferTemplateInfo from '../../../../../Additional/DetailsSections/Accounting/InternalTransferTemplateInfo';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  template: { id: number; type: TransferTemplateTypes } | null;
}

const TransferTemplateModal = memo(
  ({ isVisible, closeCallback, template }: IProps) => {
    const { t } = useTranslation(['crm', 'banking', 'common']);
    const [visible, setIsVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { response, loading } = useFetch<
      InternalTransferTemplateFromQuery | WireTransferTemplateFromQuery
    >(() => {
      if (!template) {
        return null;
      }

      switch (template.type) {
        case TransferTemplateTypes.Internal:
          return transferTemplatesAPI.fetchInternalTransferTemplateById(
            template.id,
          );

        case TransferTemplateTypes.Wire:
          return transferTemplatesAPI.fetchWireTransferTemplateById(
            template.id,
          );

        default:
          return null;
      }
    }, [template]);

    const templateContent = useMemo(() => {
      let result = null;

      if (response && template) {
        switch (template.type) {
          case TransferTemplateTypes.Internal:
            result = (
              <InternalTransferTemplateInfo
                template={transferTemplatesAPIAdapter.formatInternalTransferTemplateFromQueryToInternalTemplate(
                  response as InternalTransferTemplateFromQuery,
                )}
              />
            );
            break;

          case TransferTemplateTypes.Wire:
            result = (
              <WireTransferTemplateInfo
                template={transferTemplatesAPIAdapter.formatWireTransferTemplateFromQueryToWireTemplate(
                  response as WireTransferTemplateFromQuery,
                )}
              />
            );
            break;
        }
      }

      return result;
    }, [response, template]);

    const handleSubmit = async (reason: string) => {
      if (template) {
        try {
          setIsSubmitting(true);
          await transferTemplatesAPI.archiveTransferTemplate(template.id, {
            invalidityReason: reason,
          });
          setIsVisible(false);
          message.success(t('transfer_templates.success_archive_message'));
        } finally {
          setIsSubmitting(false);
        }
      }
    };

    const renderArchiveButton = useMemo(() => {
      if (response && template) {
        const wireTransferTemplateResponse =
          response as WireTransferTemplateFromQuery;
        return template.type === TransferTemplateTypes.Wire &&
          wireTransferTemplateResponse.status ===
            TransferTemplateStatuses.Active ? (
          <Button onClick={() => setIsVisible(true)}>
            {t('transfer_templates.archive_button')}
          </Button>
        ) : null;
      }
      return null;
    }, [template, response]);

    return (
      <ModalDialog
        title={t('banking.bank_accounts.bank_account_modal_dialog.title', {
          ns: 'banking',
        })}
        isVisible={isVisible}
        hideFooterButtons
        extendedFooterButtonsPosition="before"
        extendedFooterButtons={renderArchiveButton}
        closeCallback={closeCallback}
      >
        <LoadingWrapper loading={loading}>{templateContent}</LoadingWrapper>

        <DeleteConfirmation
          title={t('transfer_templates.reason_modal_title')}
          isLoading={isSubmitting}
          isVisible={visible}
          description={t('transfer_templates.reason_modal_description')}
          submitBtnText={t('modal.save', { ns: 'common' })}
          isSubmitButtonDanger={false}
          onSubmitCallback={handleSubmit}
          closeCallback={() => setIsVisible(false)}
        />
      </ModalDialog>
    );
  },
);

export default TransferTemplateModal;
