import React, { useMemo, useState } from 'react';

// helpers
import groupsAPI from '../../../../api/orac/groupsAPI';
import useTranslation from '../../../../hooks/useTranslation';
import { GroupBaseModel } from '../../../../typings/ORAC/groups';

// components
import { Button, message } from 'antd';
import AddEditGroupModal, {
  FormValuesModel,
} from '../../../../components/Modals/TemplateModalDialogs/ORAC/Groups/AddEditGroupModal';

interface IProps {
  onEdit: () => void;
  group: GroupBaseModel | null;
}

const EditGroup = ({ onEdit, group }: IProps) => {
  const { t } = useTranslation('orac');

  const [isModalVisible, setVisibleModal] = useState(false);

  const initialFormValues: FormValuesModel | null = useMemo(() => {
    let result = null;

    if (group) {
      const { name, description } = group;
      result = { name, description };
    }

    return result;
  }, [group]);

  const handleSubmit = async (values: FormValuesModel) => {
    await groupsAPI.editGroupById(group?.id || '', values);
    message.success(t('groups.edit.success'));
    closeCallback(true);
  };

  const closeCallback = (wasAdded?: boolean) => {
    wasAdded && onEdit();
    setVisibleModal(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisibleModal(true)}>
        {t('groups.edit.title')}
      </Button>

      <AddEditGroupModal
        title={t('groups.edit.title')}
        onSubmit={handleSubmit}
        isVisible={isModalVisible}
        closeCallback={closeCallback}
        initialFormValues={initialFormValues}
      />
    </>
  );
};

export default EditGroup;
