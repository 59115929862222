import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import { JournalFormValues } from '..';

// components
import FormField from '@core_components/FormField';
import DatePicker from '../../../../../Forms/FormComponents/DatePicker';
import JournalForm from './JournalForm';
import LoadingWrapper from '../../../../../WrapperComponents/LoadingWrapper';
import { Col, Input, Row, Typography } from 'antd';

interface IProps {
  isEdit: boolean;
}

const InnerForm = ({ isEdit }: IProps) => {
  const { t } = useTranslation('finance');
  const { values } = useFormikContext<JournalFormValues>();

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <FormField
            label={t('journals.new.journal_date')}
            name="journalValueDate"
            component={StyledDatePicker}
            disabled={!!values.journalId}
            additionalProps={{ allowClear: true }}
          />
        </Col>

        <Col offset={8} span={8}>
          <FormField
            disabled
            label={t('journals.new.journal_no')}
            name="journalId"
            component={StyledInput}
          />
        </Col>
      </Row>

      <JournalForm
        displayPostingDate={!!isEdit}
        isEdit={isEdit}
        isEditActive={values.isEditMode}
        fieldName="journal.entries"
      />

      {!!values.isReversalJournal && (
        <ReverseJournalWrapper>
          <StyledParagraph>
            {t('journals.update.reversed_journal')}
          </StyledParagraph>
          <LoadingWrapper loading={!values.reversalJournal}>
            <JournalForm
              displayPostingDate={!values.reversalJournal}
              isEdit={!values.reversalJournal}
              isEditActive={values.isEditMode}
              fieldName="reversalJournal.entries"
            />
          </LoadingWrapper>
        </ReverseJournalWrapper>
      )}
    </>
  );
};

const StyledParagraph = styled(Typography.Paragraph)`
  font-size: ${({ theme }) => theme.fontSizeSm};
  color: ${({ theme }) => theme.warningColor};
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const ReverseJournalWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginSm};
`;
export default InnerForm;
