import React, { useMemo, useState } from 'react';

// helpers
import rolesAPI from '../../../../api/orac/rolesAPI';
import useTranslation from '../../../../hooks/useTranslation';
import { RoleBaseModel } from '../../../../typings/ORAC/roles';

// components
import { Button, message } from 'antd';
import AddEditRoleModal, {
  FormValuesModel,
} from '../../../../components/Modals/TemplateModalDialogs/ORAC/Roles/AddEditRoleModal';

interface IProps {
  role: RoleBaseModel;
  onEdit?: () => void;
}

const EditRole = ({ role, onEdit }: IProps) => {
  const { t } = useTranslation('orac');

  const [isModalVisible, setVisibleModal] = useState<boolean>(false);

  const initialFormValues: FormValuesModel | null = useMemo(() => {
    let result = null;

    if (role) {
      const { name, description } = role;
      result = { name, description };
    }

    return result;
  }, [role]);

  const handleSubmit = async (values: FormValuesModel) => {
    await rolesAPI.editRoleById(role.id, values);
    message.success(t('roles.edit.success'));
    closeCallback(true);
  };

  const closeCallback = (wasAdded?: boolean) => {
    if (wasAdded) {
      onEdit && onEdit();
    }

    setVisibleModal(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisibleModal(true)}>
        {t('roles.edit.title')}
      </Button>

      <AddEditRoleModal
        title={t('roles.edit.title')}
        onSubmit={handleSubmit}
        isVisible={isModalVisible}
        closeCallback={closeCallback}
        initialFormValues={initialFormValues}
      />
    </>
  );
};

export default EditRole;
