import React from 'react';

// helpers
import { NewRoleValidationSchema } from '../../../../../../validations/orac/roles';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  title: string;
  initialFormValues: FormValuesModel | null;
  onSubmit: (values: FormValuesModel) => Promise<void>;
  isLoadingInitialValues?: boolean;
}

export interface FormValuesModel {
  name: string;
  description: string;
}

const AddEditRoleModal = ({
  isVisible,
  closeCallback,
  title,
  initialFormValues,
  onSubmit,
  isLoadingInitialValues,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      confirmExitWithoutSaving
      enableReinitialize
      initialValues={isVisible ? initialFormValues : null}
      validationSchema={NewRoleValidationSchema}
      onSubmit={onSubmit}
      renderForm={
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={() => closeCallback()}
        >
          <LoadingWrapper loading={!!isLoadingInitialValues}>
            {initialFormValues && <InnerForm />}
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default AddEditRoleModal;
